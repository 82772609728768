const host = process.env.VUE_APP_HOST
const host2 = process.env.VUE_APP_HOST_2
const host3 = process.env.VUE_APP_HOST_3

export const urls = {
  /* 登录 */
  login: `/teacher/login`,

  /* 教师信息 */
  teacherGet: '/teacher/get',

  /* 教师信息更新 */
  teacherUpdate: '/teacher/update',

  /* 教师地区更新 */
  updateArea: '/teacher/updateArea',

  /* 获取手机验证码 */
  getMsgCode: '/teacher/getMsgCode',

  /* 更新新手机号 */
  updateNewPhone: '/teacher/updateNewPhone',

  /* 修改密码 */
  updateNewPassword: 'teacher/updateNewPassword',

  /* 首页轮播图接口 */
  getSlidePic: '/webAjax/index',

  /* 首页作业包统计数量 */
  bagStatistics: '/homework/bagStatistics',

  /* 作业详情 */
  homeworkDetail: '/homework/detail',

  /* 试题详情 */
  paperQuestions: '/paperquestions/detail',

  /* 学生答案 */
  userList: '/homework/userList',

  /* 班级列表 */
  classList: '/gradeClass/findList',

  /* 班级详情 */
  gradeClassDetail: '/gradeClass/detail',

  /* 作业列表 */
  // homeworkList: '/homework/findList',
  homeworkList: '/apiv2/teacher/homework/itemPage',

  /* 省列表 */
  cityList: '/city/findList',

  /* 获取城市或者省列表 */
  getProvince: '/city/list',

  /* 市列表 */
  cityGet: '/city/getByPid',

  /* 小模块 */
  findModules: '/exam/findModules',

  /* 试题列表 */
  examList: '/exam/list',

  /* 班级列表（简） */
  gradeClassList: '/gradeClass/list',
  // gradeClassList: `${host}/gradeClass/list`,

  /* 布置作业 */
  homeworkSave: '/homework/save',

  /* 布置作业-新架构 */
  newHomeworkSave: '/apiv2/teacher/homework/saveBag',

  /* 下载离线讲评 */
  offlineDownload: `${host}/offline/download`,

  /* 根据单元获取单词列表 */
  getWordsByUnitId: '/word/unit/getWordsByUnitId',

  /* 根据模块返回单词单元列表 */
  getUnitsByModuleId: '/word/unit/getUnitsByModuleId',

  /* 音标列表 */
  pronList: '/word/pron/pronList',

  /* 获取音标的分类列表 */
  getClassifyList: '/word/pron/getClassifyList',

  /* 获取学生答案 */
  answerDetail: '/homework/answerDetail',

  /* 获取精读单元列表 */
  repeatAfterUnit: '/repeatAfterUnit/findList',

  /* 获取精读单元下的精读列表(一个单元下可能有多篇精读文章) */
  findListByUnitId: '/repeatAfterUnit/findListByUnitId',

  /* 拓展学习 */
  repeatAfter: `/repeatAfter/findList`,

  /* 获取拓展阅读模块的分类列表 */
  getRepeatAfterClassifyList: `/repeatAfter/getClassifyList`,

  /* 拓展学习内容 */
  repeatAfterSelect: `/repeatAfter/selectById`,

  // 精读：检查作业
  // 获取作业内容
  repeatDetail: '/repeatAfterUnit/getDetail',

  // 获取学生分数
  getStudentScore: '/repeatAfterUnit/userList',

  // 获取学生答案
  getAnswerDetail: '/repeatAfterUnit/getAnswerDetail2',

  // 模考作业
  // 获取大模块
  getExamModules: '/exam/getExamModule',

  // 获取试题
  getExamList: '/exam/examList',

  // 获取模考作业试题列表
  questionList: '/homeworkExam/questionList',

  homeworkExamUserList: '/homeworkExam/userList',

  // 新URl
  // 获取大模块列表:语音基础.............
  getIndexModule: '/entrance/getIndexModule',

  // 获取模块列表:根据大模块获取小模块
  getModulesByPid: '/entrance/getModulesByPid',

  // 获取学校列表
  schoolGet: '/school/findList',

  // 根据手机号查询老师
  teacherFindByPhone: '/teacher/selectByPhone',

  // 删除作业
  homeworkDelUrl: `/homework/deleteItem`,

  // 更新作业截止时间
  homeworkUpdateItem: `/homework/updateItem`,

  // 单词作业
  homeworkWordList: `/homework/wordList`,

  // 单词作业学生答案
  homeworkWordUserList: `/homework/userList`,

  // 获取作业包列表
  findBagList: `/homework/findBagList`,

  // 作业包的详情
  //   bagDetail: `/homework/bagDetail`,
    bagDetail: `/apiv2/teacher/homework/bagDetail`,

  // 子作业的详情
  itemDetail: `/apiv2/teacher/homework/itemDetail`,

  // 子作业班级报告
  classReport: '/apiv2/teacher/homework/classReport',

  // 打回重做
  backHomework: `/homework/redo`,

  // 批量打回学生作业
  batchRedo: `/homework/redo/batchRedo`,

  // 获取学生听读作业详情
  repeatAfterUnitById: `/repeatAfterUnit/findTypeById`,

  // 获取点评列表
  reviewList: `/homework/review/list`,

  // 添加点评
  addReview: `/homework/review/addReview`,

  // 催交作业
  homeworkUrge: `/homework/urge`,

  // 批量催交学生作业
  batchUrge: `/homework/urge/batchUrge`,

  // 删除作业包
  deleteHomeBag: `/homework/deleteHomeBag`,

  // 作业包导出成绩
  exportBagScore: `${host}/webAjax/exportBagScore`,

  // 修改作业包
  updateBagItem: `/homework/updateBagItem`,

  // 查询超级教师拥有的学校
  getSchoolList: `/gradeClass/getSchoolList`,

  // 查询超级教师班级
  getOtherClasses: `/gradeClass/getOtherClasses`,

  // 查询超级教师班级
  findHomeworkListBySuperId: `/homework/findHomeworkListBySuperId`,

  // 查询超级教师查看的学校 下的年级/班级
  findClassesBySchoolId: `/homework/findClassesBySchoolId`,

  // 超级教师查询选择的班级的作业情况
  findHomeworkByClassIdOld: `/homework/findHomeworkByClassIdOld`,

  // 根据SchoolId获取年级
  getGradeBySchoolId: `/gradeClass/getGradeBySchoolId`,

  // 根据学生的得分区间区分【柱形图】
  getScoreToChart: `/webAjax/getScoreToChart`,

  // 检查成绩统计报告-题型概况，作业类型是题组
  countPaperQuestionScore: `/webAjax/CountPaperQuestionScore`,

  // 检查成绩统计报告-题型概况，作业类型是题组
  countWrittenQuestion: `/webAjax/CountWrittenQuestion`,

  // 获取班级学生
  getUsersByClassId: `/userGroup/getUsersByClassId`,

  // 布置模考作业 - 补考
  superExamSaveAgain: `/superHomeworkExam/examSave`,

  // 布置模考作业 - 统一考试
  superExamSaveUnify: `/superHomeworkExam/saveSuperExamByClazz`,

  // 查询超级教师学校下的考试数量
  findSchoolListByTeacherId: `/superHomeworkExam/findSchoolListByTeacherId`,

  // 考试列表
  findExamListBySchoolId: `/superHomeworkExam/findExamListBySchoolId`,

  // 查看未完成名单
  findUnfinishedStudentList: `/superHomeworkExam/findUnfinishedStudentList`,

  // 导出未完成名单
  exportUnfinishedStudentList: `${host}/superHomeworkExam/exportUnfinishedStudentList`,

  // // 查看学生成绩列表
  // getStudentScoreDetail: `/superHomeworkExam/getStudentScoreDetail`,
  // 查看学生成绩列表
  getStudentScoreDetail: `/apiv2/teacher/mockExam/getStudentScoreDetail`,

  // 普通教师查看考试
  findExamListByTeacherId: `/superHomeworkExam/findExamListByTeacherId`,

  // 筛选学校的年级 ##普通教师筛选的是班级
  getGradeTypeById: `/superHomeworkExam/getGradeTypeById`,

  // 导出学生成绩列表
  exportStudentScoreDetail: `${host}/superHomeworkExam/exportStudentScoreDetail`,

  // 删除考试
  deleteExam: `/superHomeworkExam/deleteExam`,

  // 修改考试信息
  updateExamInfo: `/superHomeworkExam/updateExamInfo`,

  // 筛选条件  全部班级、全部试题
  getExamConditionById: `/superHomeworkExam/getExamConditionById`,

  // 导出学生成绩列表
  getScoreDetailByUserId: `/superHomeworkExam/getScoreDetailByUserId`,

  // 获取班级
  getClazzForSuperExam: `/gradeClass/getClazzForSuperExam`,

  // 获取班级
  homeworkExamAnswer: `homeworkExam/answerDetail`,

  // 根据教师ID获取该名下的所有班级
  getClazzByTeacherId: `/gradeClass/list`,

  // 批量创建班级
  createClazzs: `/gradeClass/saveList`,

  // 获取年级列表
  getGradeLists: `/gradeClass/gradeTypeList`,

  // 获取班级列表
  getClassList: `/gradeClass/findList`,

  // 查询班级列表下的学生
  getStuByClassId: `/user/list`,

  // 移除学生
  removeStu: `/user/remove`,

  // 获取学生详细信息
  getStuInfo: `/user/get`,

  //  修改学生姓名
  updateStuName: `/user/updateRealName`,

  // 班级设置
  setClass: `/gradeClass/update`,

  // 查看班级详情
  getClassDetails: `/gradeClass/detail`,

  // 分组列表
  groupList: `/userGroup/findList`,

  // 新建分组
  createGroup: `/userGroup/insert`,

  // 分组重命名
  renameGroup: `/userGroup/update`,

  // 删除分组
  removeGroup: `/userGroup/delete`,

  // 分组下的学生列表
  groupStuList: `/userGroupRelation/studentList`,

  // 单个分组添加多个学生
  addStuToGroup: `/userGroupRelation/addStudent`,

  // 移除分组中的学生
  removeStus: `/userGroupRelation/removeStudent`,

  // 将学生添加进多个分组
  stuAddToGroup: `/userGroup/changeUserGroup`,

  // 获取该学校所有教师列表
  getAllTeachers: `/teacher/findListBySchoolId`,

  // 解散班级
  removeClass: `/gradeClass/remove`,

  // 获取学习卡类型
  getCardList: `/user/get`,

  // 修改学生学号
  updateStuId: `/user/updateStudentId`,

  // 转让班级获取验证码

  saveTansfered: `/gradeClass/getMsgCode`,

  // 转让班级
  transferClass: `/gradeClass/transfer`,

  // 批量移除学生
  removeStuList: `/user/removeByUserIds`,

  // 批量点评
  batchReview: '/homework/review/batchReview',

  // 批量点评
  getBatchReviewList: '/homework/review/getBatchReviewList',

  // 删除点评
  deleteBatchReviewList: '/homework/review/deleteBatchReviewList',

  // 个人作业统计报告智能点评
  personalReviews: '/homework/getPersionalReviews',

  // 音标预览
  getWordPronunciationById: '/word/pron/getWordPronunciationById',

  // 获取教辅模块
  moduleByGradeAndBanner: '/entrance/moduleByGradeAndBanner',

  // 在线模考-统计报告-整体情况
  totalStatistics: '/superHomeworkExam/totalStatistics',

  // 在线模考-统计报告-分数段统计
  scoreStatistics: '/superHomeworkExam/scoreStatistics',

  // 在线模考-统计报告-题型统计
  questionTypeStatistics: '/superHomeworkExam/questionTypeStatistics',

  // 查询模考里面的题型
  getQuestionsType: '/superHomeworkExam/getQuestionsType',

  // 获取布置作业模块（旧）
  getOnlyIndexModule: '/entrance/getOnlyIndexModule',

  // 获取频道下的一级模块
  getFirstLevelModuleByChannel: '/apiv2/teacher/module/getFirstLevelModuleByChannel',

  // 获取全部教材版本和教材的树形结构
  textbookTreeList: '/apiv2/teacher/textbook/treeList',

  // 查询教材同步模块下的子模块和对应的试题数据
  getTextbookSyncByModule: '/apiv2/teacher/module/getTextbookSyncByModule',

  // 获取教材下面单元列表
  getTextbookUnitList: '/apiv2/teacher/textbook/unitList',

  // 获取模块下的全部子模块树
  getModuleTreeByPid: '/apiv2/teacher/module/getModuleTreeByPid',

  // 获取叶子模块下的题组or题组单元列表
  getListPaperGroup: '/apiv2/teacher/module/listPaperGroup',

  // 根据题组ID查询题组下的大题数据
  getSimplePaperQuestions: '/apiv2/teacher/paperGroup/getSimplePaperQuestions',

  // 获取趣味配音-全部列表
  findFunVoiceList: '/funVoice/findFunVoiceList',

  // 获取趣味配音-专辑列表
  findAlbumList: '/funVoice/findAlbumList',

  // 根据模块id查询专辑列表接口【分页】
  findVideoAlbumPageByModule: '/apiv2/teacher/album/findVideoAlbumPageByModule',

  // 查询趣味配音素材列表【分页】
  findSimpleVideoByModule: '/apiv2/teacher/dub/findSimpleVideoByModule',

  // 根据专辑id查询专辑详情和趣味配音列表
  getVideoAlbumDetail: '/apiv2/teacher/album/getVideoAlbumDetail',

  // 获取标签列表
  findLabelList: '/funVoice/findLabelList',

  // 筛选主题/获取标签分类列表（包含标签列表）
  listAllWithTag: '/apiv2/teacher/tagCategory/listAllWithTag',

  // 根据字典pram获取字典列表
  findListByParam: '/apiv2/teacher/common/dict/findListByParam',

  // 根据趣味配音id查询试题精简信息
  getSimpleDetailForShare: '/apiv2/teacher/dub/getSimpleDetailForShare',

  // 根据配音id获取配音详情
  getRepeatAfterById: '/funVoice/getRepeatAfterById',

  // 根据学生id和子作业id查询学生作答详情
  getAnswerByUserIdItemId: '/repeatAfterUnit/getAnswerByUserIdItemId',

  // 趣味配音-检查作业-作业内容
  getFunVoiceDetail: '/repeatAfterUnit/getFunVoiceDetail',

  // 根据号码查询教师
  selectByPhone: '/teacher/selectByPhone',

  // 查询教师下班级
  findListByTeacher: '/gradeClass/findListByTeacher',

  // 按教师查看
  findBagListByTeacher: 'homework/findBagListByTeacherForWeb',

  // 我的布置
  findBagListByCreator: 'homework/findBagListByCreatorForWeb',

  // 我的布置
  getWriteDetailNew: 'homework/getWriteDetailNew',

  // 试题预览
  iframeSrc: `${host2}/paperAnswerCountGraph/getLibPreViewAnswer`,

  // 试题预览v2
  questionPreviewUrl: `${host3}/question-preview`,

  // 查看答案 - reportType（报告类型：1=作业，2=模考，3=机房考试）
  checkReportUrl: `${host3}/check-all-answer-report`,

  // 新课文作业内容
  homeworkDetails2textSrc: `${host3}/text-content-review`,

  // 新单词短语作业内容
  homeworkDetails2wordSrc: `${host3}/word-content-review`,

  // 新单词作业报告
  homeworkReport2wordSrc: `${host3}/word-content-review-report`,

  // 新课文作业报告
  homeworkReport2textSrc: `${host3}/text-content-review-report`,

  // 作业报告（普通教师）数据概览
  getDataView: '/apiv2/teacher/getDataViewV2',

  // 导出作业报告明细表
  exportReportDetail:'/apiv2/teacher/homework/exportReportDetail',

  // 导出作业报告统计表
  exportReportStatistics:'/apiv2/teacher/homework/exportReportStatistics',

  // 作业报告（普通教师）学生报告
  getStudentReport: '/apiv2/teacher/workpaper/getStudentReport',

  // 作业报告（普通教师）个人作业报告
  getPersonalReport: '/apiv2/teacher/getPersonalReportV2',

  // 作业报告（普通教师）个人报告作业列表
  getPersonalHomeworkList: '/apiv2/teacher/getPersonalHomeworkListV2',

  // 导出作业明细
  exportHomeWordDetail:'/apiv2/teacher/homework/exportHomeworkDetail',

  // 导出作业统计
  exportHomeWordStatistics:'/apiv2/teacher/homework/exportHomeworkStatistics',

  // 作业报告（普通教师）作业列表
  getHomeWorkList: '/apiv2/teacher/workpaper/getHomeWorkList',

  // 作业报告（超级教师）数据概览
  getDataViewSuper: '/apiv2/teacher/workpaper/super/getDataView',

  // 作业报告（超级教师）班级报告
  getClassReport: '/apiv2/teacher/workpaper/super/getClassReport',

  // 模考报告（普通教师）数据概览
  getDataViewExam: '/apiv2/teacher/mockExam/getDataView',

  // 模考报告（普通教师）学生报告
  getStudentReportExam: '/apiv2/teacher/mockExam/getStudentReport',

  // 模考报告（普通教师）个人模考报告
  getPersonalReportExam: '/apiv2/teacher/mockExam/getPersonalReport',

  // 模考报告（普通教师）个人模考考试列表
  getPersonalExamList: '/apiv2/teacher/mockExam/getPersonalExamList',

  // 模考报告（超级教师）数据概览
  getDataViewExamSuper: '/apiv2/teacher/mockExam/super/getDataView',

  // 模考报告（超级教师）班级报告
  getClassReportExam: '/apiv2/teacher/mockExam/super/getClassReport',

  // 获取学生成绩详情页面的题型详情表格头列表
  getStudentScoreHeaderExam: '/apiv2/teacher/mockExam/getStudentScoreHeader',

  // 导出学生成绩详情列表
  exportStudentScoreDetailExam: '/apiv2/teacher/mockExam/exportStudentScoreDetail',

  // 获取模考信息
  getExamInfo: '/apiv2/teacher/getExamInfo',

  // 获取banner
  getSlideShowList: '/entrance/getSlideShowList',

  // 下载中心列表
  downLoadCenterList: '/apiv2/teacher/downloadCenter/getDownloadList',

  // 导出作业包全部成绩表
  exportBagScope: '/apiv2/teacher/homework/exportBagScope',

  // 导出子作业成绩表
  exportItemScope: '/apiv2/teacher/homework/exportItemScope'
}

export default urls
